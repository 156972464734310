import React from "react";
import truncate from "truncate";
import { Box, Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// components
import { ConfirmationModal } from "../../../components";
import TipLocationFormModel from "./TipLocationFormModel";
// hooks
import useVisible from "../../../../../hooks/useVisible";
import useResponse from "../../../../../hooks/useResponse";
// toast notifaction
import * as toast from "../../../../../Toast";

const filterLocationList = (locationIdList = [], locationId) => {
  return locationIdList.filter(
    (locationIdItem) => locationIdItem !== locationId
  );
};

function TipLocationItem({
  tipLocation,
  // tipId,
  parentState,
  deleteRecord,
  updateRecord,
  removeLocation,
  onLocationClick,
  locationIdList,
}) {
  const [isDeleteMode, toggleDeleteMode] = useVisible();
  const [isUpdatedMode, toggleUpdateMode] = useVisible();
  const [state, dispatch] = useResponse();
  const { id: tipLocationId, locationId, locationName } = tipLocation;

  const handleDeleteConfirm = async () => {
    dispatch.sendingRequst();
    try {
      const response = await deleteRecord(tipLocation.id);
      const data = await response.data;
      toggleDeleteMode();

      // remove location from state
      removeLocation(tipLocationId);

      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <>
      <ConfirmationModal
        title="Do you want to delete this location?"
        showModal={isDeleteMode}
        toggleModal={toggleDeleteMode}
        onConfirm={handleDeleteConfirm}
        loading={state.loading}
        error={state.error}
      />
      <TipLocationFormModel
        title="Update Location"
        showModal={isUpdatedMode}
        toggleModal={toggleUpdateMode}
        initialValues={{ locationId, id: tipLocationId }}
        onSubmit={(data) => updateRecord(data, toggleUpdateMode)}
        state={parentState}
        locationIdList={filterLocationList(locationIdList, locationId)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            padding: "16px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            width: "40%",
            cursor: "pointer",
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => onLocationClick(tipLocationId)}
        >
          <Typography
            variant="subtitle1"
            color="textColor.main"
            fontWeight="300"
          >
            {truncate(locationName, 30)}
          </Typography>
          <ArrowForwardIosIcon
            style={{
              fontSize: "17px",
              color: "rgba(163, 167, 174, 1)",
              fontWeight: "300",
            }}
          />
        </Box>
        {/* <Button
          style={{ marginLeft: 10 }}
          color="error"
          onClick={toggleDeleteMode}
        >
          delete
        </Button> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 7,
            marginTop: 1,
          }}
        >
          <Button onClick={toggleUpdateMode}>Edit</Button>
          <Button color="error" onClick={toggleDeleteMode}>
            delete
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default TipLocationItem;
