// components
import { RaceForm } from "./component";
import moment from "moment-timezone";
// hooks
import useResponse from "../../../../hooks/useResponse";
// services
import { createRace } from "../../../../services/raceEventService";
import { getTimezone } from "../../utils/timezone";
import * as toast from "../../../../Toast";
// utils
import trimObjectValues from "../../../../utils/trimObject";

function AddRace({ onBack }) {
  const [state, dispatch] = useResponse();

  const handleSubmit = async (raceData) => {
    let raceDate = moment(raceData.raceDate).tz(
      getTimezone(raceData.timezone),
      true
    );
    raceData.raceDate = raceDate.toISOString();

    dispatch.sendingRequst();
    try {
      const trimRaceData = trimObjectValues(raceData);
      const response = await createRace(trimRaceData);
      const data = await response.data;
      toast.success(data.message);
      dispatch.successResponse(data.message);

      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <RaceForm
      title="Add Race"
      onSubmit={handleSubmit}
      state={state}
      onCancel={onBack}
    />
  );
}

export default AddRace;
