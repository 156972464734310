import React from "react";
import moment from "moment-timezone";
// import { zonedTimeToUtc } from "date-fns-tz";

// import dayjs from "dayjs";
// import * as Dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import dateFns from "date-fns";
import { useField } from "formik";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function FormInputDateTime({ label, name, onChange }) {
  const [field, meta] = useField(name);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        inputFormat="dd/MM/yyyy h:mm a"
        ampmInClock={false}
        size="small"
        label={label}
        {...field}
        minDate={moment().toDate()}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default FormInputDateTime;
