import React from "react";
import Logo from "../../../assets/logo.png";
import styles from "./topBar.module.css";

function TopBar() {
  return (
    <div className={styles.container}>
      <img
        className={styles.logoImage}
        src={Logo}
        title="make it fit logo"
        width="80"
        height="80"
        alt="make it fit logo"
      />
    </div>
  );
}

export default TopBar;
