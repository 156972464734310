import http from "./httpService";

let apiEndpoint = http.apiUrl + "upload";

// API is used to image upload
export function imageUploadAPI(image) {
  const form = new FormData();
  form.append("image", image);

  return http.post(apiEndpoint, form);
}

// API is used to upload image without thumbnail
export function imageUploadWithoutThumbAPI(image) {
  const form = new FormData();
  form.append("image", image);

  apiEndpoint = http.apiUrl + "upload/without-thumb";

  return http.post(apiEndpoint, form);
}
