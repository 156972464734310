import "./App.css";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// pages components
import { NetworkDetector } from "./components";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import PageNotFound from "./pages/notFound";

// public article page
import PublicArticle from "./pages/dashboard/pages/Article/PublicArticle";
// public configuration page
import TermsConditions from "./pages/dashboard/pages/Config/TermsConditions";
import PrivacyPolicy from "./pages/dashboard/pages/Config/PrivacyPolicy";

// services
import auth from "./services/authService";

// toast notifaction
import ToastNotificationContainer from "./Toast";

function App() {
  return (
    <>
      <ToastNotificationContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
        />

        <Route
          path="/reset/:token"
          element={
            <GuestRoute>
              <ResetPassword />
            </GuestRoute>
          }
        />

        <Route
          path="/Dashboard/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/article/:id"
          element={
            <GuestRoute>
              <PublicArticle />
            </GuestRoute>
          }
        />
        <Route
          path="/terms-conditions"
          element={
            <GuestRoute>
              <TermsConditions />
            </GuestRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <GuestRoute>
              <PrivacyPolicy />
            </GuestRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

const ProtectedRoute = ({ children }) => {
  const user = auth.getCurrentUser();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const GuestRoute = ({ children }) => {
  const user = auth.getCurrentUser();

  if (!user) {
    return children;
  }

  return <Navigate to="/dashboard" replace />;
};

export default NetworkDetector(App);
