import axios from "axios";
import * as toast from "../Toast";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 409 &&
    error.response.status <= 500;

  if (expectedError) {
    toast.error(`An unexpected error occurrred, Internal Server Error.`);
    // alert("An unexpected error occurrred, Internal Server Error");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Content-Type"] = "application/json";

const baseUrl = "https://lines-app.herokuapp.com/";
// const baseUrl = "http://localhost:8080/";

const apiUrl = `${baseUrl}api/v1/`;

const httpMethodsObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
  apiUrl,
  baseUrl,
};

export default httpMethodsObject;
