import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useField } from "formik";

export default function FormSelect({ name, label, onTimeZoneChange }) {
  const [field] = useField(name);

  const handleChange = (event) => {
    field.onChange(event);
  };

  return (
    <FormControl size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={field.value}
        name={name}
        label={label}
        onChange={handleChange}
      >
        <MenuItem value="AWST">Perth (AWST)</MenuItem>
        <MenuItem value="ACWST">Eucla (ACWST)</MenuItem>
        <MenuItem value="ACST">Adelaide (ACST)</MenuItem>
        <MenuItem value="AEST">Sydney (AEST)</MenuItem>
        <MenuItem value="LHST">Lord Howe Island (LHST)</MenuItem>
      </Select>
    </FormControl>
  );
}
