import React from "react";
import styles from "./logo.module.css";
import LogoWithText from "./../assets/logo-with-text.png";

import { makeStyles } from "@mui/styles";
// import Logo from "./../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "180px",
    // height: "80",
    marginTop: "20px",
  },
}));

function Logo() {
  const classes = useStyles();
  return (
    <div className={styles.logoContainer}>
      <img src={LogoWithText} alt="make it fit logo" className={classes.root} />
    </div>
  );
}

export default Logo;
