import React from "react";
import { Card, Stack } from "@mui/material";
import { Portal } from ".";
import { HeaderTitleSubtitle, ErrorMessage } from "../../../components";
import styles from "./modal.module.css";

function ModelWrapper({ toggleModal, title, subtitle, note, error, children }) {
  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.modalContainer}>
        <Card className={`${styles.modalCard} ${styles.confirmModal}`}>
          <Stack spacing={0.5} className="mb-10">
            <HeaderTitleSubtitle
              title={title}
              subtitle={subtitle}
              note={note}
            />
            {error && <ErrorMessage message={error} />}
          </Stack>
          {children}
        </Card>
      </div>
    </Portal>
  );
}

export default ModelWrapper;
