import { useState } from 'react'

// hook used to showing or hiding some content in components like other hooks
export default function useVisible(initialVisibilityValue = false) {
    const [isVisible, setIsVisible] = useState(initialVisibilityValue);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    }

    return [isVisible, toggleVisibility]
}
