import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/logo-with-text-light.png";

import { makeStyles } from "@mui/styles";

import styles from "./sideNav.module.css";
// import { Typography } from "@mui/material";

import ConfirmationModal from "./ConfirmationModal";

import useVisible from "../../../hooks/useVisible";

import * as toast from "../../../Toast";

import * as authService from "../../../services/authService";

// icons
import PeopleIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PeopleIcon from "@mui/icons-material/People";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import ArticleIcon from "@mui/icons-material/Article";
// import SettingsIcon from "@mui/icons-material/Settings";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import LogoutIcon from "@mui/icons-material/Logout";

const NavLinkList = [
  {
    id: 1,
    to: "/dashboard/venues?page=1",
    menuName: "Venues",
    icon: LocationOnIcon,
  },
  {
    id: 2,
    to: "/dashboard/home?page=1",
    menuName: "Users",
    icon: PeopleIcon,
  },
  // {
  //   to: "/dashboard/locations?page=1",
  //   menuName: "Locations",
  //   icon: LocationOnIcon,
  // },

  // {
  //   to: "/dashboard/races?page=1",
  //   menuName: "Race Calendar",
  //   icon: DirectionsCarIcon,
  // },
  // {
  //   to: "/dashboard/tips?page=1",
  //   menuName: "Tips",
  //   icon: TipsAndUpdatesIcon,
  // },
  // {
  //   to: "/dashboard/articles?page=1",
  //   menuName: "Articles",
  //   icon: ArticleIcon,
  // },
  {
    id: 3,
    to: "/dashboard/config",
    menuName: "Configuration",
    icon: SettingsIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "180px",
    marginTop: "20px",
    padding: "20px",
  },
}));

function SideNav() {
  const classes = useStyles();

  const [showLogoutModal, toggleLogoutModal] = useVisible(false);

  const handleLogout = () => {
    authService.logout();
    toast.success("Successfully logged out!");
    toggleLogoutModal();
    window.location.reload();
  };

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to logout?"
        showModal={showLogoutModal}
        toggleModal={toggleLogoutModal}
        onConfirm={handleLogout}
        ConfirmButtonText="LOGOUT"
      />
      <div className={styles.sideNav}>
        <div className={styles.logoHeader}>
          <img
            src={Logo}
            title="make it fit logo"
            alt="make it fit logo"
            className={classes.root}
          />
        </div>
        <div className={styles.menuList}>
          {NavLinkList.map(({ menuName, icon: Icon, to, id }) => (
            <NavLink
              key={id}
              to={to}
              className={(navData) =>
                navData.isActive
                  ? `${styles.active} ${styles.menuItem}`
                  : styles.menuItem
              }
            >
              <Icon className={styles.icon} />
              {/* <img src={blackIcon} className={styles.icon} alt="Nav icons" /> */}
              <span>{menuName}</span>
            </NavLink>
          ))}

          <div className={styles.menuItem} onClick={toggleLogoutModal}>
            <LogoutIcon className={styles.icon} />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNav;
