import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// article pages
import ArticleDetail from "./ArticleDetail";
import ArticleListing from "./ArticleListing";
import EditArticle from "./EditArticle";
import AddArticle from "./AddArticle";

function Article() {
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/dashboard/articles/add-article");
  };

  const handleNavigateToListing = () => {
    navigate(-1);
  };

  const handleEditClick = (raceId) => {
    navigate(`/dashboard/articles/edit-article/${raceId}`);
  };

  const handleArticleDetailClick = (articleId) => {
    navigate(`/dashboard/articles/article-detail/${articleId}`);
  };

  const handleArticlePublicViewClick = (articleId) => {
    const link = document.createElement("a");
    link.href = `/article/${articleId}`;

    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <ArticleListing
              onAdd={handleAddClick}
              onBack={handleNavigateToListing}
              onEdit={handleEditClick}
              onDetail={handleArticleDetailClick}
            />
          }
        />
        <Route
          path="/add-article"
          element={<AddArticle onBack={handleNavigateToListing} />}
        />
        <Route
          path="/article-detail/:id"
          element={
            <ArticleDetail
              onBack={handleNavigateToListing}
              onPublicView={handleArticlePublicViewClick}
            />
          }
        />
        <Route
          path="/edit-article/:id"
          element={<EditArticle onBack={handleNavigateToListing} />}
        />
      </Routes>
    </Layout>
  );
}

export default Article;
