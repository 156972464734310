import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastNotificationContainer = () => (
  <ToastContainer
    position="top-right"
    // autoClose={5000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    bodyStyle={{ fontSize: 15, fontFamily: "var(--secondary-font)" }}
  />
);

export default ToastNotificationContainer;

const autoCloseTime = 1500;

function info(message) {
  toast.info(message, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

function success(message) {
  toast.success(message, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

function error(message) {
  toast.error(message, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

function warning(message, isClose = true) {
  toast.warn(message, {
    position: "top-right",
    autoClose: isClose ? autoCloseTime : false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

function defaultToast(message) {
  toast(message, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}

export { error, info, success, warning, defaultToast };
