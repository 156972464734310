function trimObjectValues(object = {}) {
  const newObject = {};
  for (let key in object) {
    if (typeof object[key] === "string") {
      newObject[key] = object[key].trim();
    } else {
      newObject[key] = object[key];
    }
  }

  return newObject;
}

export default trimObjectValues;
