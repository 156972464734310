import React from "react";
import { useField } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

function FormSwitch({ label, name }) {
  const [field] = useField(name);

  // <FormControlLabel
  //   value="top"
  //   labelPlacement="top"
  //   control={<Switch color="primary" />}
  //   label={label}
  //   {...field}
  // />
  return (
    <FormControlLabel
      control={<Switch checked={field.value} />}
      label={label}
      {...field}
    />
  );
}

export default FormSwitch;
