import React from "react";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "white !important",
      border: "2px solid #2087f5 !important",
      color: "black !important",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "white !important",
      border: "2px solid #2087f5 !important",
      color: "black !important",
    },
  },
}));

function CustomPagination() {
  const classes = useStyles();
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      className={classes.root}
      size="medium"
      // @ts-expect-error
      renderItem={(props2) => (
        <PaginationItem
          {...props2}
          classes={{ selected: classes.selected }}
          disableRipple
          style={{ fontSize: 15 }}
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default CustomPagination;
