import React from "react";

// components
import { VenueForm } from "./components";

// hooks
import useResponse from "../../../../hooks/useResponse";

// services
import { createVenue } from "../../../../services/venueService";

import * as toast from "../../../../Toast";
// utils

import { imageUploadWithoutThumbAPI } from "../../../../services/uploadService";

function AddVenue({ onBack }) {
  const [state, dispatch] = useResponse();

  const handleSubmit = async (data) => {
    const { selectedThumbImage, selectedBannerImage } = data;

    // const thumbImageRes = await imageUploadWithoutThumbAPI(selectedThumbImage);
    // const bannerImageRes = await imageUploadWithoutThumbAPI(
    //   selectedBannerImage
    // );

    if (selectedThumbImage) {
      const thumbImageRes = await imageUploadWithoutThumbAPI(
        selectedThumbImage
      );
      data.thumbImage = thumbImageRes.data.file.filename;
    } else data.thumbImage = "";

    if (selectedBannerImage) {
      const bannerImageRes = await imageUploadWithoutThumbAPI(
        selectedBannerImage
      );

      data.bannerImage = bannerImageRes.data.file.filename;
    } else data.bannerImage = "";

    const venueData = {
      venueName: data.venueName,
      // thumbImage: thumbImageRes.data.file.filename,
      // bannerImage: bannerImageRes.data.file.filename,
      thumbImage: data.thumbImage,
      bannerImage: data.bannerImage,
      venueType: data.venueType,
      venueAddress: data.venueAddress,
      latitude: data.latitude,
      longitude: data.longitude,
      isRecommended: data.isRecommended,
      // location: data.location,
      // waitTime: data.waitTime,
      venueVibe: data.venueVibe,
      venueDetails: data.venueDetails,
      status: true,
    };

    dispatch.sendingRequst();
    try {
      // const trimVenueData = trimObjectValues(venueData);
      const response = await createVenue(venueData);
      const data = await response.data;
      toast.success(data.message);
      dispatch.successResponse(data.message);

      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <VenueForm
      title="Venue"
      onSubmit={handleSubmit}
      state={state}
      onCancel={onBack}
    />
  );
}

export default AddVenue;
