import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Stack, Typography } from "@mui/material";

import {
  FormInput,
  FormInputDate,
  FormImageInput,
  FormSwitch,
  SubmitLoadingButton,
  TinyEditor,
} from "../../../../../components/Forms";

// date time picker support this format - yyyy-MM-ddThh:mm
const initialValue = {
  articleTitle: "",
  articleData: "",
  articleDataText: "",
  articleImage: "",
  selectedImage: "",
  articleDate: moment().format("YYYY-MM-DDTHH:mm"),
  status: false,
  checkResolution: false,
};

function ArticleForm({
  onSubmit,
  state,
  onCancel,
  title = "",
  isEdit = false,
  initialValues = initialValue,
}) {
  // article form validationSchema
  const validationSchema = Yup.object().shape({
    articleTitle: Yup.string()
      .required()
      .min(2)
      .max(50)
      .label("Article Title")
      .trim(),
    articleData: Yup.string().min(5).label("Article Detail").trim(),
    articleDataText: Yup.string()
      .required("Article Detail is required")
      .min(5, "Article Detail must be at least 5 characters")
      .label("Article Detail")
      .trim(),
    articleDate: Yup.date().required().label("Article Publish Date"),
    status: Yup.boolean().required().label("Publish Status"),
    checkResolution: Yup.boolean().required().oneOf([false]),
    selectedImage: isEdit
      ? Yup.mixed()
          .optional()
          .test(
            "type",
            "Only the following formats are accepted: .jpeg, .jpg, .bmp, .gif and .png",
            (value) => {
              if (!value) {
                return true;
              }

              return (
                value &&
                (value.type === "image/jpeg" ||
                  value.type === "image/png" ||
                  value.type === "image/gif" ||
                  value.type === "image/jpg")
              );
            }
          )
          .label("Image")
      : Yup.mixed()
          .required("Article Image is required")
          // .test(
          //   "resolutions",
          //   "The selected image must have 600*600 resolution.",
          //   (value) => {
          //     if (value) {
          //       const objectUrl = URL.createObjectURL(value);
          //       const image = new Image();
          //       image.src = objectUrl;

          //       let result = true;
          //       image.onload = (img) => {
          //         const width = image.width;
          //         const height = image.height;
          //         console.log("onload run : ", result);
          //         console.log("Width : ", width, "Height : ", height);

          //         if (width < 600 && height < 600) {
          //           return false;
          //         }
          //       };
          //       return result;
          //     }

          //     return false;
          //   }
          // )
          .test(
            "type",
            "Only the following formats are accepted: .jpeg, .jpg, .bmp, .gif and .png",
            (value) => {
              return (
                value &&
                (value.type === "image/jpeg" ||
                  value.type === "image/png" ||
                  value.type === "image/gif" ||
                  value.type === "image/jpg")
              );
            }
          )
          .label("Image"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, setFieldTouched, setFieldError }) => (
        <Box
          sx={{
            margin: "50px",
            // width: "400px",
            display: "flex",
            flexDirection: "column",
            gap: 1.8,
          }}
        >
          {title && <Typography variant="h5">{title}</Typography>}
          <FormInput label="Article Title" name="articleTitle" type="text" />
          <TinyEditor
            label="Article Detail"
            name="articleData"
            dataTextName="articleDataText"
            type="text"
            onChangeText={(value) => {
              setFieldValue("articleDataText", value);
            }}
            onBlur={() => {
              setFieldTouched("articleDataText", true);
            }}
            onChange={(value) => {
              setFieldTouched("articleDataText", true);
              setFieldTouched("articleData", true);
              setFieldValue("articleData", value);
            }}
            onError={(message) => {
              setFieldError("articleData", message);
            }}
          />
          <FormImageInput
            name="selectedImage"
            imageValues={values.articleImage}
            onChangeSelectedImage={(event) => {
              setFieldTouched(event.target.name);
              setFieldValue(event.target.name, event.target.files[0]);
            }}
            onError={(message) => {
              setFieldError("selectedImage", message);
            }}
            onCheckRes={(checkResolution) => {
              setFieldValue("checkResolution", checkResolution);
            }}
            checkResolution={values.checkResolution}
          />
          <FormInputDate
            label="Publish Date"
            name="articleDate"
            onChange={(value) => {
              setFieldValue("articleDate", value);
            }}
          />
          <FormSwitch label="Active Status" name="status" />
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
          {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
        </Box>
      )}
    </Formik>
  );
}

export default ArticleForm;
