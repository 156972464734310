import { DataGrid } from "@mui/x-data-grid";
// import { styled } from "@mui/material/styles";
import { styled } from "@mui/material";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: ['"Work Sans", sans-serif'].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
  ".MuiDataGrid-sortIcon": {
    color: "black",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    alignItems: "center",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "RGB(250, 250, 250)",
    width: "100%",
    borderBottom: "none",
    padding: "0px 20px",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    fontSize: "15px",
    curser: "pointer",
  },
  ".MuiDataGrid-cellContent:first-letter": {
    // textTransform: "uppercase !important",
  },
  ".MuiDataGrid-row": {
    width: "100%",
    fontSize: "15px",
    padding: "0px 20px",
    cursor: "pointer",
  },
  "& .awaiting-response": {
    backgroundColor: "#fbe7e6 !important",
  },
  "& .awaiting-response:hover": {
    backgroundColor: "#fbe7e6 !important",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "RGB(250, 250, 250)",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "none",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ".MuiButtonBase-root.MuiIconButton-root": {
    fontSize: "30px",
  },
}));

export default StyledDataGrid;
