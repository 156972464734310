import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { Formik } from "formik";

import {
  convertDateTimeTogivenTimezone,
  getTimezone,
} from "../../../utils/timezone";

import {
  FormInput,
  FormInputDateTime,
  FormSelect,
  LocationDropDown,
  SubmitLoadingButton,
} from "../../../../../components/Forms";

import { getAllLocation } from "../../../../../services/locationService";
import { Loader, ErrorMessage } from "../../../../../components";

// race event validationSchema
const validationSchema = Yup.object().shape({
  raceName: Yup.string().required().min(2).max(100).label("Race Name").trim(),
  raceDate: Yup.date().required().label("Race date & time"),
  locationId: Yup.string().required().min(2).label("Location Name").trim(),
  locationAddress: Yup.string()
    .required()
    .min(2)
    .max(100)
    .label("Location Address")
    .trim(),
});

// date time picker support this format - yyyy-MM-ddThh:mm

const initialValue = {
  raceName: "",
  raceDate: new Date(),
  locationId: "",
  locationAddress: "",
  timezone: "AEST",
};

function RaceForm({
  title = "",
  onSubmit,
  initialValues = initialValue,
  state,
  onCancel,
}) {
  const { isError, isLoading, data, error } = useQuery(
    ["locations"],
    () => getAllLocation(),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const locationList = data.data.data["locations"];

  const handleTimezoneChange = (date, timezone) => {
    return convertDateTimeTogivenTimezone(date, timezone);
  };

  const handleTimezone = (timezone) => {
    return getTimezone(timezone);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Box
          sx={{
            margin: "50px",
            width: "450px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {title && <Typography variant="h5">{title}</Typography>}
          <FormInput label="Race Name" name="raceName" type="text" />
          <LocationDropDown
            label="Location Name"
            name="locationId"
            locationList={locationList}
          />
          <FormInput
            label="Location Address"
            name="locationAddress"
            type="text"
          />
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <FormInputDateTime
              label="Race Date & Time"
              name="raceDate"
              onChange={(value) => {
                setFieldValue("raceDate", value);
              }}
              value={handleTimezoneChange(values.raceDate, values.timezone)}
              timezone={handleTimezone(values.timezone)}
            />
            <FormSelect label="Timezone" name="timezone" />
          </Stack>
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
          {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
        </Box>
      )}
    </Formik>
  );
}

export default RaceForm;
