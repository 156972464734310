import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
// components
import {
  ConfirmationModal,
  CustomPagination,
  StyledDataGrid,
} from "../../../components";
import { LocationFormModel } from ".";
// hooks
import useQuery from "../../../../../hooks/useQuery";
import useVisible from "../../../../../hooks/useVisible";
import useResponse from "../../../../../hooks/useResponse";

import * as toast from "../../../../../Toast";

// utils
import trimObjectValues from "../../../../../utils/trimObject";

function ListingTable({
  columns,
  // onEditClick,
  deleteRecord,
  updateRecord,
  controller,
  refreshState,
  result = [],
  pages = 0,
  deleteModelTitle = "",
}) {
  const [isDeleteMode, toggleDeleteMode] = useVisible();
  const [isEditMode, toggleEditMode] = useVisible();
  const [state, dispatch] = useResponse();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(result);

  const [totalPages, setTotalPages] = useState(pages);
  const [locationId, setLocationId] = useState();
  const [queryParams, setQueryParams] = useQuery();

  let page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  page = page - 1;

  const fetchData = useCallback(
    async ({ page }) => {
      try {
        setLoading(true);
        const response = await controller({
          page: page,
          limit: 10,
        });
        const { data } = await response.data;
        setData(data.result);
        setTotalPages(data.total);
      } catch (ex) {
        toast.error(ex.response.statusText);
      }
      setLoading(false);
    },
    [controller]
  );

  useEffect(() => {
    fetchData({ page: page });
  }, [fetchData, page]);

  useEffect(() => {
    if (refreshState) fetchData({ page: page });
  }, [refreshState, fetchData, page]);

  const handleCellClick = (params) => {
    const row = params.row;
    const value = params.field;
    if (value === "edit") {
      setLocationId(row.id);
      return toggleEditMode();
    }

    if (value === "delete") {
      setLocationId(row.id);
      return toggleDeleteMode();
    }
  };

  const handleDeleteConfirm = async () => {
    dispatch.sendingRequst();
    try {
      const response = await deleteRecord(locationId);
      const data = await response.data;
      toggleDeleteMode();

      // refetch data again
      fetchData({ page: page });

      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleEditConfirm = async (locationData) => {
    dispatch.sendingRequst();
    const trimLocationData = trimObjectValues(locationData);
    try {
      const response = await updateRecord(locationId, trimLocationData);
      const data = await response.data;
      toggleEditMode();

      // refetch data again
      fetchData({ page: page });

      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const location = data.filter((location) => location.id === locationId)[0];

  return (
    <>
      <ConfirmationModal
        title={deleteModelTitle}
        note="Deleting the location will remove the Races and Tips associated with it."
        showModal={isDeleteMode}
        toggleModal={() => {
          toggleDeleteMode();
          dispatch.reset();
        }}
        onConfirm={handleDeleteConfirm}
        loading={state.loading}
        error={state.error}
      />
      <LocationFormModel
        title="Edit Location"
        showModal={isEditMode}
        toggleModal={() => {
          toggleEditMode();
          dispatch.reset();
        }}
        onSubmit={handleEditConfirm}
        initialValues={{ locationArea: location && location.locationArea }}
        state={state}
      />
      <StyledDataGrid
        rows={data}
        columns={columns}
        rowCount={totalPages}
        pageSize={10}
        rowsPerPageOptions={[10]}
        page={page}
        onPageChange={(page) => {
          setQueryParams({ page: page + 1 });
        }}
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        paginationMode="server"
        onCellClick={handleCellClick}
        //   sortModel={sort}
        //   onSortModelChange={(model) => {
        //     setSort(model);
        //   }}
        onFilterModelChange={(model) => console.log(model)}
        loading={loading}
        hideFooterSelectedRowCount={true}
        getRowClassName={(params) => {
          return params.row.checkInDone ? "awaiting-response" : "";
        }}
        autoHeight
        components={{
          Pagination: CustomPagination,
          // ColumnSortedAscendingIcon: MdArrowDropUp,
          // ColumnSortedDescendingIcon: MdArrowDropDown,
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No rows
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No result
            </Stack>
          ),
        }}
      />
    </>
  );
}

export default ListingTable;
