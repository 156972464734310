import React from 'react'
import styles from "./loader.module.css";

function Loader() {
    return (
        <div className={styles.loader}>Loading...</div>
    )
}

export default Loader
