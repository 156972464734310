import React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useField } from "formik";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};

const names = ["Dancefloor", "Latenight", "Techno"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const FormSelectVenueVibe = ({ label, name }) => {
  const theme = useTheme();
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    helpers.setValue(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl sx={{ width: "100%" }} error={!!meta.error}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={field.value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, field.value, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{meta.error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default FormSelectVenueVibe;
