import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useField } from "formik";

const FormSelectVenueType = ({ name, label }) => {
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    field.onChange(event);
  };

  return (
    <TextField
      select
      id="select"
      size="small"
      label={label}
      {...field}
      onChange={handleChange}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
    >
      <MenuItem value="Nightclub">Nightclub</MenuItem>
      <MenuItem value="Pub">Pub</MenuItem>
      <MenuItem value="Bar">Bar</MenuItem>
    </TextField>
  );
};

export default FormSelectVenueType;
