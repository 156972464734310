import React from "react";
import DotLoader from "../DotLoader";
import styles from "./formButton.module.css";

function ConfirmButton({
  buttonText,
  onClick,
  size = 2.2,
  isBackground = true,
  submitButton = false,
  borderRadius = 8,
  loading = false,
  ...otherProps
}) {
  return (
    <button
      style={{ fontSize: `${size}rem`, borderRadius: `${borderRadius}px` }}
      className={`${styles.button} ${
        isBackground ? styles.withBackground : styles.noBackground
      }`}
      onClick={onClick}
      {...otherProps}
      disabled={loading}
    >
      {loading ? <DotLoader /> : buttonText}
    </button>
  );
}

export default ConfirmButton;
