import React from "react";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { Link, Box } from "@mui/material";
import { CardContainer, Card } from "../../components/UI";
import { HeaderTitleSubtitle, SuccessAndErrorMessage } from "../../components";
import { Form, FormInput, SubmitLoadingButton } from "../../components/Forms";

import useResponse from "../../hooks/useResponse";
import trimObjectValues from "../../utils/trimObject";
import * as authService from "../../services/authService";
import * as toast from "../../Toast";

import styles from "../login/login.module.css";

// forgot password validationSchema
const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(6).email().label("Email").trim(),
});

const ForgotPassword = () => {
  const [state, dispatch] = useResponse();

  const handleSubmit = async (loginData, formkiObject) => {
    dispatch.sendingRequst();
    loginData = trimObjectValues(loginData);
    try {
      const response = await authService.forgotPassword(loginData);
      // window.location = "/login";
      formkiObject.resetForm();
      toast.info(response.data.message);
      dispatch.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <CardContainer>
      <Card>
        <div className={styles.headingContainer}>
          <HeaderTitleSubtitle
            title="Forgot Password"
            subtitle="Enter your registered email to forgot password."
          />
        </div>
        <Form
          initialValues={{
            email: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            <FormInput label="Email" name="email" type="email" />
            <SubmitLoadingButton
              buttonText="Forgot Password"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
            <SuccessAndErrorMessage
              error={state.error}
              success={state.message}
            />

            <Link
              variant="subtitle1"
              component={RouterLink}
              to="/login"
              align="center"
            >
              Login
            </Link>
          </Box>
        </Form>
      </Card>
    </CardContainer>
  );
};

export default ForgotPassword;
