import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Sidebar } from "./components";

// screens
import User from "./pages/User/User";
import Location from "./pages/Location/Location";
import RaceEvent from "./pages/RaceEvent/RaceEvent";
import Venue from "./pages/Venue/Venue";
import Tips from "./pages/Tips/Tips";
import Article from "./pages/Article/Article";
import Config from "./pages/Config/Config";

import styles from "./dashboard.module.css";

function Dashboard() {
  return (
    <div className={styles.container}>
      <div>
        <Sidebar />
      </div>
      <div className={styles.mainContainer}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard/home" />} />
          <Route path="/home" element={<User />} />
          <Route path="/locations/*" element={<Location />} />
          <Route path="/venues/*" element={<Venue />} />
          <Route path="/races/*" element={<RaceEvent />} />
          <Route path="/articles/*" element={<Article />} />
          <Route path="/tips/*" element={<Tips />} />
          <Route path="/config" element={<Config />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
