import http from "./httpService";

const apiEndpoint = http.apiUrl + "location";

function locationURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all locations
export function getAllLocation() {
  return http.get(apiEndpoint);
}

// service is used to get all locations with pagination
export function getAllLocationWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single location
export function getSingleLocation(id) {
  return http.get(locationURl(id));
}

// service is used to create location
export function createLocation(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update Location
export function updateLocation(id, data) {
  return http.put(locationURl(id), data);
}

// service is used to delete location
export function deleteLocation(id) {
  return http.delete(locationURl(id));
}
