import React from "react";

import { Layout } from "../../components";

// user pages
import UserListing from "./UserListing";

function User() {
  return (
    <Layout>
      <UserListing
      // onAdd={handleAddClick}
      // onBack={handleNavigateToListing}
      // onEdit={handleEditClick}
      />
    </Layout>
  );
}

export default User;
