import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// venue pages
import VenueListing from "./VenueListing";
import AddVenue from "./AddVenue";
import EditVenue from "./EditVenue";

function Venue() {
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/dashboard/venues/add-venue");
  };

  const handleNavigateToListing = () => {
    // navigate("/dashboard/races/");
    navigate(-1);
  };

  const handleEditClick = (venueId) => {
    navigate(`/dashboard/venues/edit-venue/${venueId}`);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <VenueListing
              onAdd={handleAddClick}
              onBack={handleNavigateToListing}
              onEdit={handleEditClick}
            />
          }
        />
        <Route
          path="/add-venue"
          element={<AddVenue onBack={handleNavigateToListing} />}
        />
        <Route
          path="/edit-venue/:id"
          element={<EditVenue onBack={handleNavigateToListing} />}
        />
      </Routes>
    </Layout>
  );
}

export default Venue;
