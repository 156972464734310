import { MenuItem, TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

function LocationDropDown({ label, name, locationList = [] }) {
  const [field, meta] = useField(name);

  return (
    <TextField
      select
      id="select"
      size="small"
      label={label}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
    >
      {locationList.map((locationItem) => (
        <MenuItem value={locationItem.id} key={locationItem.id}>
          {locationItem.locationArea}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default LocationDropDown;
