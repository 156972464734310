import http from "./httpService";

const apiEndpoint = http.apiUrl + "tip-location";

function tipLocationURl(id) {
  return `${apiEndpoint}/${id}`;
}

function tipLocationByTipIDURl(tipId) {
  return `${apiEndpoint}/by-tip/${tipId}`;
}

// service is used to get all Tip Locations
export function getAllTipLocation() {
  return http.get(apiEndpoint);
}

// service is used to get all Tip Locations by tip id
export function getAllTipLocationByTipId(tipId) {
  return http.get(tipLocationByTipIDURl(tipId));
}

// service is used to get details of single Tip Location
export function getSingleTipLocation(id) {
  return http.get(tipLocationURl(id));
}

// service is used to create Tip Location
export function createTipLocation(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update Tip Location
export function updateTipLocation(id, data) {
  return http.put(tipLocationURl(id), data);
}

// service is used to delete Tip Location
export function deleteTipLocation(id) {
  return http.delete(tipLocationURl(id));
}
