import moment from "moment-timezone";
import React, { useCallback } from "react";
import { Stack, Button } from "@mui/material";
import { ListingTable } from "./components";

// services
import {
  deleteVenue,
  getAllVenuesWithPagination,
} from "../../../../services/venueService";

function VenueListing({ onAdd, onEdit }) {
  return (
    <Stack spacing={5} padding={3}>
      <Stack>
        <ListingTable
          columns={columns}
          onAdd={onAdd}
          onEditClick={onEdit}
          controller={useCallback(
            async (data) => await getAllVenuesWithPagination(data),
            []
          )}
          deleteRecord={deleteVenue}
          deleteModelTitle="Do you want to delete this venue?"
        />
      </Stack>
    </Stack>
  );
}

export default VenueListing;

const columns = [
  {
    field: "venueName",
    headerName: "Venue Name",
    flex: 1,
  },
  {
    field: "venueType",
    headerName: "Venue Type",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    valueGetter: (params) =>
      params.row.status === true ? "Active" : "Inactive",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    minWidth: 100,
    flex: 1,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 100,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
