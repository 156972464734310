import { Stack, Box, Typography } from "@mui/material";

function ConfigWrapper({ title, children }) {
  return (
    <Stack
      spacing={5}
      // padding={3}
      style={{ overflowY: "scroll" }}
      // overflow="scroll"
      height="100vh"
      margin="0 auto"
    >
      <Stack maxWidth={1000} margin="0 auto">
        <Stack padding={2} backgroundColor="white.main">
          <Typography variant="h4" fontWeight="700" color="textColor.main">
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textColor.main"
            style={{ margin: "10px 0px" }}
            fontWeight="300"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {children}
            </Box>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ConfigWrapper;
