import http from "./httpService";

const apiEndpoint = http.apiUrl + "config";

function configURl(id) {
  return `${apiEndpoint}/${id}`;
}

// service is used to get configuration data
export function getConfig() {
  return http.get(apiEndpoint);
}

// service is used to get privary policy data
export function getPrivacyPolicy() {
  return http.get(apiEndpoint + "/privacy");
}

// service is used to get terms and conditions data
export function getTermsConditions() {
  return http.get(apiEndpoint + "/terms");
}

// service is used to update configuration data
export function updateConfig(id, data) {
  return http.put(configURl(id), data);
}
