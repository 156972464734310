import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// components
import { VenueForm } from "./components";
import { Loader, ErrorMessage } from "../../../../components";

// hooks
import useResponse from "../../../../hooks/useResponse";

// services
import { getSingleVenue, updateVenue } from "../../../../services/venueService";
import * as toast from "../../../../Toast";

// utils
import trimObjectValues from "../../../../utils/trimObject";
import { imageUploadWithoutThumbAPI } from "../../../../services/uploadService";

function EditVenue({ onBack }) {
  const navigate = useNavigate();
  const [state, dispatch] = useResponse();
  const params = useParams();
  const venueId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["venue-edit", venueId],
    () => getSingleVenue(venueId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const venue = data.data.data["venue"];

  const handleSubmit = async (venueData) => {
    const { selectedThumbImage, selectedBannerImage } = venueData;

    dispatch.sendingRequst();
    try {
      const updatedVenueData = {
        venueName: venueData.venueName,
        venueType: venueData.venueType,
        venueAddress: venueData.venueAddress,
        latitude: venueData.latitude,
        longitude: venueData.longitude,
        thumbImage: venueData.thumbImage,
        bannerImage: venueData.bannerImage,
        isRecommended: venueData.isRecommended,
        venueVibe: venueData.venueVibe,
        venueDetails: venueData.venueDetails,
        status: venueData.status,
      };

      if (selectedThumbImage) {
        const thumbImageRes = await imageUploadWithoutThumbAPI(
          selectedThumbImage
        );
        updatedVenueData.thumbImage = thumbImageRes.data.file.filename;
      }

      if (selectedBannerImage) {
        const bannerImageRes = await imageUploadWithoutThumbAPI(
          selectedBannerImage
        );

        updatedVenueData.bannerImage = bannerImageRes.data.file.filename;
      }

      const trimVenueData = trimObjectValues(updatedVenueData);
      const response = await updateVenue(venueId, trimVenueData);
      const data = await response.data;
      toast.success(data.message);
      dispatch.successResponse(data.message);

      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <VenueForm
      title="Edit Venue"
      isEdit={true}
      initialValues={{
        venueName: venue.venueName,
        venueType: venue.venueType,
        venueVibe: venue.venueVibe,
        venueDetails: venue.venueDetails,
        status: venue.status,
        venueAddress: venue.venueAddress,
        thumbImage: venue.thumbImage,
        bannerImage: venue.bannerImage,
        latitude: venue.latitude,
        longitude: venue.longitude,
        location: venue.location,
        isRecommended: venue.isRecommended,
      }}
      onSubmit={handleSubmit}
      state={state}
      onCancel={onBack}
    />
  );
}

export default EditVenue;
