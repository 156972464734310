import React from "react";
import { useFormikContext } from "formik";
import { LoadingButton } from "@mui/lab";

function FormButton({
  buttonText,
  onClick,
  isBackground = true,
  submitButton = false,
  loading = false,
}) {
  const { handleSubmit } = useFormikContext();

  const handleClick = (e) => {
    if (submitButton) {
      handleSubmit(e);
    } else {
      onClick();
    }
  };

  return (
    <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant={isBackground ? "contained" : "outlined"}
      onClick={handleClick}
      loading={loading}
      disabled={loading}
    >
      {loading ? "loading..." : buttonText}
    </LoadingButton>
  );
}

export default FormButton;
