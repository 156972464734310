import React from "react";
import { TipLocationForm } from ".";
import { ModelWrapper } from "../../../components";

const initialValue = {
  locationId: "",
};

function TipLocationFormModel({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  state,
  locationIdList,
  initialValues = initialValue,
}) {
  if (!showModal) return null;
  return (
    <ModelWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      error={state.error}
    >
      <TipLocationForm
        onSubmit={onSubmit}
        state={state}
        onCancel={toggleModal}
        initialValues={initialValues}
        locationIdList={locationIdList}
      />
    </ModelWrapper>
  );
}

export default TipLocationFormModel;
