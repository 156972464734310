import React from "react";
import { LocationForm } from ".";
import { ModelWrapper } from "../../../components";

const initialValue = {
  locationArea: "",
};

function LocationFormModel({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  state,
  initialValues = initialValue,
}) {
  if (!showModal) return null;
  return (
    <ModelWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      error={state.error}
    >
      <LocationForm
        onSubmit={onSubmit}
        state={state}
        toggleModal={toggleModal}
        initialValues={initialValues}
      />
    </ModelWrapper>
  );
}

export default LocationFormModel;
