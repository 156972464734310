import http from "./httpService";

const apiEndpoint = http.apiUrl + "tip";

function tipURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all Tips
export function getAllTip() {
  return http.get(apiEndpoint);
}

// service is used to get all tips with pagination
export function getAllTipWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single Tip
export function getSingleArticle(id) {
  return http.get(tipURl(id));
}

// service is used to create Tip
export function createTip(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update Tip
export function updateTip(id, data) {
  return http.put(tipURl(id), data);
}

// service is used to delete Tip
export function deleteTip(id) {
  return http.delete(tipURl(id));
}
