import React from "react";
import styles from "./layout.module.css";

function Layout({
  children,
  topRadius = false,
  heightReduce = false,
  ...other
}) {
  return (
    <div
      className={`${styles.mainContainer} ${topRadius ? styles.topRadius : ""} 
      ${heightReduce ? styles.heightReduce : ""} `}
      {...other}
    >
      {children}
    </div>
  );
}

export default Layout;
