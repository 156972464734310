import http from "./httpService";

const apiEndpoint = http.apiUrl + "venue";

function venueEventURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all venues
export function getAllRace() {
  return http.get(apiEndpoint);
}

// service is used to create venue
export function createVenue(data) {
  return http.post(apiEndpoint, data);
}

// service is used to get all venues events with pagination
export function getAllVenuesWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to delete venue event
export function deleteVenue(id) {
  return http.delete(venueEventURl(id));
}

// service is used to get details of single venue event
export function getSingleVenue(id) {
  return http.get(venueEventURl(id));
}

// service is used to update venue event
export function updateVenue(id, data) {
  return http.put(venueEventURl(id), data);
}
