import { useQuery } from "react-query";

// components
import { ConfigWrapper } from "./components";
import { Loader, ErrorMessage } from "../../../../components";
import { ShowHtmlContent } from "../Article/component";

// services
import { getPrivacyPolicy } from "../../../../services/configService";

function PrivacyPolicy() {
  const { isError, isLoading, data, error } = useQuery(
    ["privacy-policy"],
    () => getPrivacyPolicy(),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const { privacyPolicyDetail } = data?.data?.data["privacyPolicy"];

  return (
    <ConfigWrapper title="Privacy Policy">
      <ShowHtmlContent content={privacyPolicyDetail} />
    </ConfigWrapper>
  );
}

export default PrivacyPolicy;
