import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  FormImageInput,
  FormInput,
  FormSelectVenueType,
  FormSelectVenueVibe,
  FormSwitch,
  FormTextArea,
  SubmitLoadingButton,
} from "../../../../../components/Forms";
import FormGoogleAutocomplete from "../../../../../components/Forms/FormGoogleAutocomplete";

const initialValue = {
  venueName: "",
  venueType: "",
  venueVibe: [],
  venueDetails: "",
  venueAddress: "",
  selectedThumbImage: "",
  thumbImage: "",
  selectedBannerImage: "",
  latitude: "",
  longitude: "",
  // waitTime: 0,
  bannerImage: "",
  status: false,
  isRecommended: false,
  checkResolution: false,
};

function VenueForm({
  title = "",
  onSubmit,
  initialValues = initialValue,
  state,
  onCancel,
  isEdit = false,
}) {
  // form validationSchema
  const validationSchema = Yup.object().shape({
    venueName: Yup.string()
      .required()
      .min(2)
      .max(100)
      .label("Venue Name")
      .trim(),
    venueDetails: Yup.string()
      .required()
      .min(2)
      .max(2000)
      .label("Venue Details")
      .trim(),
    venueType: Yup.string().required().label("Venue Type").trim(),
    venueVibe: Yup.array().required().min(1).label("Venue Vibe"),
    status: Yup.boolean().required().label("Venue Status"),
    isRecommended: Yup.boolean().required().label("Venue Recommended"),
    checkResolution: Yup.boolean().optional().oneOf([false]),
    venueAddress: Yup.string().required().label("Venue Address"),
    latitude: Yup.string().label("latitude"),
    longitude: Yup.string().label("longitude"),
    // location: Yup.mixed().label("location"),
    selectedThumbImage: Yup.mixed()
      .optional()
      .test(
        "type",
        "Only the following formats are accepted: .jpeg, .jpg, .bmp, .gif and .png",
        (value) => {
          if (!value) {
            return true;
          }

          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/gif" ||
              value.type === "image/jpg")
          );
        }
      )
      .label("Thumbnail Image"),

    selectedBannerImage: Yup.mixed()
      .optional()
      .test(
        "type",
        "Only the following formats are accepted: .jpeg, .jpg, .bmp, .gif and .png",
        (value) => {
          if (!value) {
            return true;
          }

          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/gif" ||
              value.type === "image/jpg")
          );
        }
      )
      .label("Banner Image"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, setFieldTouched, setFieldError }) => (
        <Box
          sx={{
            margin: "50px",
            width: "450px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {title && <Typography variant="h5">{title}</Typography>}

          <FormInput label="Venue Name" name="venueName" type="text" />

          {/* <FormInput label="Venue Address" name="venueAddress" type="text" /> */}

          <FormImageInput
            name="selectedThumbImage"
            label="Thumbnail Image"
            labelResolution="300 * 300 px"
            imageValues={values.thumbImage}
            onChangeSelectedImage={(event) => {
              setFieldTouched(event.target.name);
              setFieldValue(event.target.name, event.target.files[0]);
            }}
            onError={(message) => {
              setFieldError("selectedThumbImage", message);
            }}
            onCheckRes={(checkResolution) => {
              setFieldValue("checkResolution", checkResolution);
            }}
            checkResolution={values.checkResolution}
          />

          <FormImageInput
            name="selectedBannerImage"
            label="Banner Image"
            labelResolution="450 * 300 px"
            imageValues={values.bannerImage}
            onChangeSelectedImage={(event) => {
              setFieldTouched(event.target.name);
              setFieldValue(event.target.name, event.target.files[0]);
            }}
            onError={(message) => {
              setFieldError("selectedBannerImage", message);
            }}
            onCheckRes={(checkResolution) => {
              setFieldValue("checkResolution", checkResolution);
            }}
            // checkResolution={values.checkResolution}
          />

          <FormTextArea label="Venue Details" name="venueDetails" type="text" />

          <FormSelectVenueType name="venueType" label="Venue Type" />

          <FormSelectVenueVibe name="venueVibe" label="Venue Vibe" />

          <FormGoogleAutocomplete
            label="Venue Address"
            name="venueAddress"
            type="text"
            setFieldValue={setFieldValue}
          />

          <FormSwitch label="Recommended Status" name="isRecommended" />

          {isEdit && <FormSwitch label="Active Status" name="status" />}

          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
          {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
        </Box>
      )}
    </Formik>
  );
}

export default VenueForm;
