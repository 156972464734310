import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, useField } from "formik";
import SuccessAndErrorMessage from "../SuccessAndErrorMessage";

function TinyEditor({
  name,
  dataTextName,
  onChange,
  onError,
  onBlur,
  onChangeText,
}) {
  const [field] = useField(name);

  return (
    <>
      <Editor
        onKeyUp={(e) => {
          // Clear timer
        }}
        apiKey={"3lvy7r8d6ewnc8f74rslfeocg68t5t64exf6hyhwxhc5w3ov"}
        value={field.value}
        onInit={(evt, editor) => {
          const text = editor.getContent({ format: "text" }).trim();
          onChangeText(text);
        }}
        onBlur={onBlur}
        onEditorChange={(newValue, editor) => {
          const text = editor.getContent({ format: "text" }).trim();
          // const charCount =
          // editor.plugins.wordcount.body.getCharacterCountWithoutSpaces();
          // if (this.props.charLimit !== 0 && charCount > this.props.charLimit) {
          //   this.handleErrorWhenTyping();
          //   return;
          // }
          // this.handleResponseTextChange(newValue, charCount);
          // if (newValue.length > 0) {
          //   this.props.setTryForResponse();
          // }

          onChange(newValue);
          onChangeText(text);
        }}
        init={{
          remove_linebreaks: false,
          height: "400",
          selector: "textarea",
          lists_indent_on_tab: false,
          default_link_target: "_blank",
          skin: "snow",
          icons: "thin",
          plugins: [
            "lists advlist preview link image paste help wordcount code paste ",
          ],
          toolbar:
            "preview undo redo | formatselect | bold italic underline code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | paste pastetext| help",
          content_style: `@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");
 body { font-family:'Outfit', sans-serif; color:#252729 }`,
        }}
      />
      <ErrorMessage name={dataTextName}>
        {(message) => <SuccessAndErrorMessage error={message} />}
      </ErrorMessage>
    </>
  );
}

export default TinyEditor;
