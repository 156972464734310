import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// location pages
import ListingLocation from "./ListingLocation";
// import AddLocation from "./AddLocation";
// import EditLocation from "./EditLocation";

function Location() {
  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/dashboard/locations/add-location");
  };

  const handleNavigateToListing = () => {
    navigate("/dashboard/locations/");
  };

  const handleEditClick = (locationId) => {
    navigate(`/dashboard/locations/edit-location/${locationId}`);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <ListingLocation
              onAdd={handleAddClick}
              onBack={handleNavigateToListing}
              onEdit={handleEditClick}
            />
          }
        />
        {/* <Route
          path="/add-location"
          element={<AddLocation onBack={handleNavigateToListing} />}
        /> */}
        {/* <Route
          path="/edit-location/:id"
          element={<EditLocation onBack={handleNavigateToListing} />}
        /> */}
      </Routes>
    </Layout>
  );
}

export default Location;
